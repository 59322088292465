export enum TooltipPosition {
    TOP = 'top',
    LEFT ='left',
    RIGHT = 'right',
    TOP_RIGHT ='top-right',
    RIGHT_TOP = 'right-top',
    BOTTOM = 'bottom',
    CENTER= 'center',
    END = 'end',
    START = 'start'
}
