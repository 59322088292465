import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core';

import {
    ExplanationPosition,
    ExplanationTooltipComponent,
} from '~/app/shared/components/explanation-tooltip/explanation-tooltip.component';

@Component({
    selector: 'eb-h2',
    templateUrl: './h2.component.html',
    styleUrls: ['./h2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ExplanationTooltipComponent],
})
export class H2Component {
    @Input()
    public explanationKey?: string;

    @Input()
    public explanationPosition: ExplanationPosition[] = [
            {
                position: 'top',
                value: '-twelfth',
            },
            {
                position: 'left',
                value: 'fourth',
            },
        ];
}
