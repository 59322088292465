import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
    RouterLink,
    RouterLinkWithHref,
} from '@angular/router';


import { RESUME_FEATURES } from '~/app/shared/enums/resume-features.enum';
import { TooltipPosition } from '~/app/shared/enums/tooltip-position.enum';
import { PermissionParam } from '~/app/shared/types/authorisation/permission-param.type';
import { BinaryOperator } from '~/app/shared/types/binary-operator.type';

import { BasePermissionsService } from '../../services/base-permissions/base-permissions.service';

export type AuthorisationColorMode = 'default' | 'light' | 'dark';

export type AuthorisationBehavior = 'freeze' | 'hide';

export type AuthorisationCursorStyle = 'default' | 'not-allowed' | 'pointer' | 'help';

@Directive({
    selector: '[ebAuthorisation]',
    standalone: true,
})
export class AuthorisationDirective implements AfterViewInit, OnDestroy, OnChanges {
    @Input()
    public authorisationBehavior: AuthorisationBehavior = 'freeze';

    @Input()
    public authorisationExplanationKey: string = 'authorisation.wrong_plan';

    @Input({ required: true })
    public authorisationPermissions: string[] = [];

    @Input()
    public authorisationResume: RESUME_FEATURES | null = null;

    @Input()
    public authorisationExtraInfoKey: string | null = null;

    @Input()
    public authorisationParams: PermissionParam[] = [];

    @Input()
    public authorisationPermissionsOperator: BinaryOperator = 'AND';

    @Input()
    public authorisationOffsetX: number = 0;

    @Input()
    public authorisationOffsetY: number = 12;

    @Input()
    public authorisationPosition: TooltipPosition = TooltipPosition.TOP;

    @Input()
    public customClass: string[] = [];

    @Input()
    public authorisationColorMode: AuthorisationColorMode = 'default';

    @Input()
    public isHostElementHidden?: boolean = false;

    @Input()
    public tooltipClass: string = 'tooltip';

    @Input()
    public width: number | string = 'auto';

    @Input()
    public cursorStyle: AuthorisationCursorStyle = 'pointer';

    @Input()
    public protectedRouterLink: any[] | string = [];

    @Input()
    public isLockIconHidden: boolean = false;

    @Input()
    public isPositionRelative: boolean = true;

    @Input()
    public isBackgroundColorGrey: boolean = false;

    @Input()
    public listenOnPermissionParamsChanges: boolean = false;

    @Input()
    public isOpacityPreserved: boolean = false;

    @Output()
    public protectedClick = new EventEmitter();

    private permissionsService: BasePermissionsService = inject(BasePermissionsService);

    private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

    private elementRef: ElementRef<HTMLElement> = inject(ElementRef) as ElementRef<HTMLElement>;

    private renderer: Renderer2 = inject(Renderer2);

    private router: Router = inject(Router);

    private routerLink: RouterLink | null = inject(RouterLink, { optional: true });

    private routerLinkWithHref: RouterLinkWithHref | null = inject(RouterLink, { optional: true });

    private isAuthorised: boolean = false;

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        if (this.isAuthorised) {
            if (this.protectedRouterLink.length > 0) {
                // Verification url tree
                if (Array.isArray(this.protectedRouterLink)) {
                    void this.router.navigate(this.protectedRouterLink, { relativeTo: this.activatedRoute });
                } else {
                    void this.router.navigateByUrl(this.protectedRouterLink);
                }
            } else {
                this.protectedClick.next(event);
            }
        } else {
            this.permissionsService.onLockedFeatureClick(this.authorisationResume ?? null, this.authorisationPermissions, this.authorisationExtraInfoKey);
            event.preventDefault();
            event.stopPropagation();
        }
    }

    public ngAfterViewInit(): void {
        this.alterHostElementBasedOnPermissions();
    }

    public ngOnDestroy(): void {
        this.permissionsService?.onDispose();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            this.listenOnPermissionParamsChanges
            && changes?.authorisationParams
            && !changes?.authorisationParams.isFirstChange()
            && changes.authorisationParams.currentValue !== changes.authorisationParams.previousValue
        ) {
            this.alterHostElementBasedOnPermissions();
        }
    }


    private alterHostElementBasedOnPermissions() {
        const link = this.routerLink || this.routerLinkWithHref;
        this.isAuthorised = this.permissionsService.hasPermissions(
            this.authorisationPermissions,
            this.authorisationParams,
            this.authorisationPermissionsOperator,
        );

        if (link) {
            this.protectLink(link);
        }
        this.handleClasses();
    }

    private protectLink(link: RouterLink) {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const { onClick } = link;
        // eslint-disable-next-line no-param-reassign
        link.onClick = (...args) => {
            if (this.isAuthorised) {
                return onClick.apply(link, args);
            }
            return false;
        };
    }

    private handleClasses() {
        const hostElement = this.elementRef.nativeElement;
        const action = this.isAuthorised ? this.renderer.removeClass.bind(this.renderer) : this.renderer.addClass.bind(this.renderer);

        this.handleBaseUnauthorisedClass(hostElement, action);
        this.handleOpacityClass(hostElement, action);
        this.handleCustomClasses(hostElement, action);
        this.handleCursorStyleClass(hostElement, action);
        this.handleHiddenClass(hostElement, action);
        this.handleLockIconClass(hostElement, action);
        this.handleColorModeClass(hostElement, action);
        this.handleAffectPositionClass(hostElement, action);
        this.handleHoverBackgroundGrayClass(hostElement, action);
    }

    private handleBaseUnauthorisedClass(hostElement: any, action: Function) {
        action(hostElement, 'unauthorised');
    }

    private handleOpacityClass(hostElement: any, action: Function) {
        if (this.isOpacityPreserved) {
            action(hostElement, 'unauthorised--opacity-full');
        }
    }

    private handleCustomClasses(hostElement: any, action: Function) {
        if (this.customClass?.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            this.customClass.forEach((x) => action(hostElement, x));
        }
    }

    private handleCursorStyleClass(hostElement: any, action: Function) {
        if (this.cursorStyle) {
            action(hostElement, `cursor-${this.cursorStyle}`);
        }
    }

    private handleHiddenClass(hostElement: any, action: Function) {
        if (this.authorisationBehavior === 'hide') {
            action(hostElement, 'd-none');
        }
    }

    private handleLockIconClass(hostElement: any, action: Function) {
        if (this.isLockIconHidden) {
            action(hostElement, 'unauthorised--no-lock-icon');
        }
    }

    private handleColorModeClass(hostElement: any, action: Function) {
        if (this.authorisationColorMode !== 'default') {
            action(hostElement, `unauthorised--${this.authorisationColorMode}`);
        }
    }

    private handleAffectPositionClass(hostElement: any, action: Function) {
        if (this.isPositionRelative) {
            action(hostElement, 'unauthorised--position-relative');
        }
    }

    private handleHoverBackgroundGrayClass(hostElement: any, action: Function) {
        if (this.isBackgroundColorGrey) {
            action(hostElement, 'unauthorised--hover-background-color-grey');
        }
    }
}
