import {
    ComponentType,
    ConnectedPosition,
    Overlay,
    OverlayConfig,
    OverlayRef,
} from '@angular/cdk/overlay';
import {
    ComponentPortal,
    TemplatePortal,
} from '@angular/cdk/portal';
import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import { TooltipPosition } from '~/app/shared/enums/tooltip-position.enum';

@Directive({
    selector: '[ebTooltip]',
    standalone: true,
})
export class TooltipDirective {
    @Input()
    public display?: boolean = true;

    @Input()
    public ebTooltipTemplate!: TemplateRef<unknown>;

    @Input()
    public ebTooltipTemplateContext!: { [key: string]: unknown };


    @Input()
    public ebTooltipComponent!: ComponentType<unknown>;

    @Input()
    public maxWidth !: number;

    @Input()
    public tooltipClass: string = 'tooltip';

    @Input()
    public width: number | string = 'auto';

    @Input()
    public offsetX: number = 0;

    @Input()
    public offsetY: number = 12;

    @Input() position: TooltipPosition = TooltipPosition.TOP;

    @Input()
    public showOnClick: boolean = false;

    @Output()
    public locked = new EventEmitter<boolean>();

    public overlayRef?: OverlayRef;

    private isInstantiationLocked: boolean = false;

    private CONNECTED_POSITION_MAP: { [key:string]: ConnectedPosition } = {
        top: {
            originX: TooltipPosition.CENTER,
            originY: TooltipPosition.BOTTOM,
            overlayX: TooltipPosition.CENTER,
            overlayY: TooltipPosition.TOP,
            offsetX: 0,
        },
        'top-right': {
            originX: TooltipPosition.END,
            originY: TooltipPosition.BOTTOM,
            overlayX: TooltipPosition.END,
            overlayY: TooltipPosition.TOP,
            offsetX: 0,
        },
        right: {
            originX: TooltipPosition.END,
            originY: TooltipPosition.CENTER,
            overlayX: TooltipPosition.START,
            overlayY: TooltipPosition.CENTER,
            offsetX: 0,
        },
        'right-top': {
            originX: TooltipPosition.END,
            originY: TooltipPosition.TOP,
            overlayX: TooltipPosition.START,
            overlayY: TooltipPosition.TOP,
            offsetX: 0,
        },
        left: {
            originX: TooltipPosition.START,
            originY: TooltipPosition.CENTER,
            overlayX: TooltipPosition.END,
            overlayY: TooltipPosition.CENTER,
            offsetX: 0,
        },
        bottom: {
            originX: TooltipPosition.CENTER,
            originY: TooltipPosition.TOP,
            overlayX: TooltipPosition.CENTER,
            overlayY: TooltipPosition.BOTTOM,
            offsetX: 0,
        },
    };

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
    ) {

    }

    @HostListener('mouseenter', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onMouseEnter(event: MouseEvent) {
        if (!this.isInstantiationLocked && this.display && !this.showOnClick) this.show();
    }

    @HostListener('mouseleave', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onMouseLeave(event: MouseEvent) {
        if (this.display && !this.showOnClick) this.destroyComponent();
    }


    public show(): void {
        this.isInstantiationLocked = true;
        if (this.ebTooltipTemplate) {
            const templatePortal = new TemplatePortal(this.ebTooltipTemplate, this.viewContainerRef, this.ebTooltipTemplateContext);
            this.overlayRef = this.overlay.create(this.getOverlayConfig());
            this.overlayRef.attach(templatePortal);
            this.locked.emit(true);
        }
        if (this.ebTooltipComponent) {
            const componentPortal = new ComponentPortal(this.ebTooltipComponent, this.viewContainerRef);
            this.overlayRef = this.overlay.create(this.getOverlayConfig());
            this.overlayRef.attach(componentPortal);
            this.locked.emit(true);
        }
    }

    public destroyComponent(): void {
        this.isInstantiationLocked = false;
        this.overlayRef?.detach();
        this.overlayRef?.dispose();
        this.overlayRef = undefined;
    }

    public getOverlayConfig(): OverlayConfig {
        const positionBuilder = this.overlay.position()
            .flexibleConnectedTo(this.elementRef)
            .setOrigin(this.elementRef);

        return ({
            width: this.width,
            positionStrategy: positionBuilder.withPositions([
                this.getPreferredConnectedPositions(TooltipPosition.TOP),
                this.getPreferredConnectedPositions(TooltipPosition.BOTTOM),
                this.getPreferredConnectedPositions(TooltipPosition.LEFT),
                this.getPreferredConnectedPositions(TooltipPosition.RIGHT),
            ]),
            panelClass: 'tooltip',
        });
    }


    public getPreferredConnectedPositions(position: TooltipPosition): ConnectedPosition {
        const tempPosition = {
            ...this.CONNECTED_POSITION_MAP?.[position],
            offsetX: this.offsetX,
            panelClass: `tooltip--${position}`,
        };
        if (position === 'left') {
            return {
                ...tempPosition,
                offsetX: -tempPosition.offsetX,
            };
        }
        if (position === 'bottom') {
            return {
                ...tempPosition,
                offsetY: -this.offsetY,
            };
        }
        if (position === 'top' || position === 'top-right') {
            return {
                ...tempPosition,
                offsetY: this.offsetY,
            };
        }

        return tempPosition;
    }
}
