<div
    ebTooltip
    class="explanation__content"
    [ebTooltipTemplate]="tooltipContent"
>
    <ng-content />
</div>
@if (explanationKey) {
    <eb-icon
        ebTooltip
        class="explanation__icon no-print"
        [ngClass]="positionClasses"
        [ebTooltipTemplate]="tooltipContent"
        [icon]="explanationIconUri"
        width="14"
    />
}
<ng-template #tooltipContent>
    <div class="explanation__tooltip">
        @if (explanationTitleKey | transloco) {
            <h3 class="mt-0">
                {{ explanationTitleKey | transloco }}
            </h3>
        }
        @if (isExplanationTranslatable && explanationKey | transloco) {
            <p class="my-0">
                {{ explanationKey | transloco }}
            </p>
        } @else {
            <p class="my-0">
                {{ explanationKey }}
            </p>
        }
    </div>
</ng-template>
