import {
    NgClass,
    NgTemplateOutlet,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
} from '@angular/core';

import {
    AuthorisationColorMode,
    AuthorisationDirective,
} from '~/app/shared/directives/authorisation/authorisation.directive';
import { RESUME_FEATURES } from '~/app/shared/enums/resume-features.enum';
import { PermissionParam } from '~/app/shared/types/authorisation/permission-param.type';

import { LabelComponent } from '../label/label.component';

export type ButtonComponentColor = 'primary' | 'secondary' | 'secondary-gray' | 'white' | 'black' | 'default';
export type ButtonComponentSize = 'very-small' | 'small' | 'default' | 'large';
export type ButtonComponentWidth = 'auto' | 'full';
export type ButtonComponentType = 'submit' | 'reset' | 'button' | 'menu';
export type ButtonComponentRadius = 'small' | 'default';
export type ButtonComponentStyle = 'default' | 'outline';

@Component({
    selector: 'eb-button, a[eb-button], button[eb-button]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AuthorisationDirective,
        NgClass,
        NgTemplateOutlet,
        LabelComponent,
    ],
})
export class ButtonComponent implements OnChanges {
    @Input()
    public color: ButtonComponentColor = 'primary';

    @Input()
    public size: ButtonComponentSize = 'default';

    @Input()
    public width: ButtonComponentWidth = 'auto';

    @Input()
    public type: ButtonComponentType = 'submit';

    @Input()
    public radius: ButtonComponentRadius = 'default';

    @Input()
    public outline: boolean = false;

    @HostBinding('disabled')
    @Input()
    public disabled: boolean = false;

    @Input()
    public isLoading: boolean = false;

    @Input()
    public buttonPermissions : string[] = [];

    @Input()
    public buttonPermissionParams: PermissionParam[] = [];

    @Input()
    public buttonResumeFeature: RESUME_FEATURES | null = null;

    @Input()
    public buttonPermissionExtraInfoKey: string | null = null;

    @Input()
    public buttonPermissionColorMode: AuthorisationColorMode | null = null;

    @Input()
    public listenOnPermissionParamsChanges: boolean = false;

    @Output()
    public buttonClick = new EventEmitter();

    @HostBinding('class')
    public hostClasses: string[] = [];

    public isInlineComponent: boolean = ['A', 'BUTTON'].includes(this.elementRef.nativeElement.tagName);

    public buttonClasses: string[] = [];

    public constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) {
        this.refreshClasses();
    }

    public ngOnChanges(): void {
        this.refreshClasses();
    }

    public refreshClasses() {
        this.buttonClasses = [
            'button',
            `button--${(this.outline ? 'outline-' : '')}${this.color}`,
            `button--${this.size}`,
            `button--${this.width}`,
            `button--radius-${this.radius}`,
        ];

        if (this.isInlineComponent) {
            this.hostClasses = this.buttonClasses;
        }
    }

    public onButtonClick() {
        this.buttonClick.emit();
    }
}
