@if (explanationKey?.length > 0) {
    <svg-icon
        ebTooltip
        [class.icon--color-black]="color === 'black'"
        [class.icon--color-primary]="color === 'primary'"
        [class.icon--color-white]="color === 'white'"
        [ebTooltipTemplate]="tooltipContent"
        [src]="'/assets/icons/' + icon + '.svg'"
        [svgStyle]="{ 'width.px': width, 'cursor': cursor }"
    />
}
@if (!explanationKey) {
    <svg-icon
        [class.icon--color-primary]="color === 'primary'"
        [class.icon--color-white]="color === 'white'"
        [src]="'/assets/icons/' + icon + '.svg'"
        [svgStyle]="{ 'width.px': width, 'cursor': cursor }"
    />
}
<ng-template #tooltipContent>
    @if (explanationKey) {
        <div class="explanation__tooltip">
            {{ explanationKey | transloco }}
        </div>
    }
</ng-template>
