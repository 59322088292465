import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { SvgIconComponent } from 'angular-svg-icon';

import { TooltipDirective } from '../../directives/tooltip/tooltip';

@Component({
    selector: 'eb-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SvgIconComponent,
        TooltipDirective,
        TranslocoPipe,
    ],
})
export class IconComponent {
    @Input()
    public icon?: string;

    @Input()
    public width?: 'auto' | number;

    @Input()
    public cursor: string = 'inherit';

    @Input()
    public color?: 'primary' | 'white' | 'gray' | 'black';

    @Input()
    public explanationKey?: string;
}
