@if (!isInlineComponent) {
    <button
        ebAuthorisation
        [ngClass]="buttonClasses"
        [attr.disabled]="disabled ? 'disabled' : null"
        [authorisationExtraInfoKey]="buttonPermissionExtraInfoKey"
        [authorisationParams]="buttonPermissionParams"
        [authorisationPermissions]="buttonPermissions"
        [authorisationResume]="buttonResumeFeature"
        [isLockIconHidden]="true"
        [listenOnPermissionParamsChanges]="listenOnPermissionParamsChanges"
        [type]="type"
        (protectedClick)="onButtonClick()"
    >
        <ng-container [ngTemplateOutlet]="buttonContent" />
    </button>
}

@if (isInlineComponent) {
    <ng-container [ngTemplateOutlet]="buttonContent" />
}

<ng-template #buttonContent>
    <eb-label
        ebAuthorisation
        [authorisationColorMode]="buttonPermissionColorMode ?? color === 'primary' ? 'dark' : 'default'"
        [authorisationExtraInfoKey]="buttonPermissionExtraInfoKey"
        [authorisationParams]="buttonPermissionParams"
        [authorisationPermissions]="buttonPermissions"
        [authorisationResume]="buttonResumeFeature"
        [listenOnPermissionParamsChanges]="listenOnPermissionParamsChanges"
        size="lg"
    >
        <ng-content />
        @if (isLoading) {
            <svg
                class="button__spinner"
                viewBox="0 0 50 50"
            >
                <circle
                    class="button__spinner-path"
                    cx="25"
                    cy="25"
                    fill="none"
                    r="20"
                    stroke-width="5"
                />
            </svg>
        }
    </eb-label>
</ng-template>
