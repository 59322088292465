import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

import { TooltipDirective } from '../../directives/tooltip/tooltip';
import { IconComponent } from '../icon/icon.component';

export type ExplanationPosition = {
    position: 'top' | 'left' | 'right' | 'bottom',
    value: number | string,
}

@Component({
    selector: 'eb-explanation-tooltip',
    templateUrl: './explanation-tooltip.component.html',
    styleUrls: ['./explanation-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TooltipDirective,
        IconComponent,
        NgClass,
        TranslocoPipe,
    ],
})
export class ExplanationTooltipComponent implements OnInit {
    @Input()
    public explanationKey?: string;

    @Input()
    public explanationTitleKey?: string;

    @Input()
    public isExplanationTranslatable: boolean = true;

    @Input()
    public explanationPosition: ExplanationPosition[] = [
            {
                position: 'top',
                value: '-twelfth',
            },
            {
                position: 'left',
                value: 'eighth',
            },
        ];

    @Input()
    public explanationIconUri: string = 'ui/action/symbols/info';

    @Input()
    public isBackgroundOpaque: boolean = false;

    @HostBinding('class.withContent')
    @Input()
    public withContent: boolean = false;

    public positionClasses: string = '';

    ngOnInit(): void {
        if (this.explanationPosition?.length) {
            const newClasses = this.explanationPosition
                .map((item: ExplanationPosition) => `${item.position}-${item.value}`);
            this.positionClasses = [
                'position-absolute',
                (this.isBackgroundOpaque ? 'explanation__icon--white' : ''),
                ...newClasses,
            ].join(' ');
        }
    }
}
