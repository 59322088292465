import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

import {
    ExplanationPosition,
    ExplanationTooltipComponent,
} from '~/app/shared/components/explanation-tooltip/explanation-tooltip.component';

export type LabelComponentColor = 'primary' | 'secondary' | 'flat-secondary' | 'white' | 'black' | 'danger' | 'default';
export type LabelComponentSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type LabelComponentWeight = 'light' | 'normal' | 'bold' | 'black';
export type LabelComponentTextTransform = 'uppercase' | 'capitalize' | 'lowercase' | 'none' | 'unset' | 'inherit' | 'initial';
export type LabelComponentTextAlign = 'left' | 'center' | 'right';
export type LabelComponentTextDecoration = 'underline' | 'italic' | 'strike' | 'normal';
export type LabelComponentWordBreak = 'word' | 'all';
@Component({
    selector: 'eb-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ExplanationTooltipComponent],
})
export class LabelComponent implements OnChanges {
    @Input()
    public explanationKey?: string;

    @Input()
    public explanationTitleKey?: string;

    @Input()
    public explanationPosition: ExplanationPosition[] = [];

    @Input()
        color: LabelComponentColor = 'default';

    @Input()
        size: LabelComponentSize = 'md';

    @Input()
        weight: LabelComponentWeight = 'normal';

    @Input()
        textTransform: LabelComponentTextTransform = 'inherit';

    @Input()
        textAlign: LabelComponentTextAlign | null = null;

    @Input()
        textDecoration: LabelComponentTextDecoration | null = null;

    @Input()
        wordBreak: LabelComponentWordBreak | null = null;

    @Input()
        isBackgroundOpaque: boolean = false;

    @HostBinding('class')
    private hostClasses: string = '';

    public ngOnChanges(changes: SimpleChanges): void {
        const weight = changes.weight?.currentValue as string || this.weight;
        const size = changes.size?.currentValue as string || this.size;
        const color = changes.color?.currentValue as string || this.color;
        const textTransform = changes.textTransform?.currentValue as string || this.textTransform;
        const textAlign = changes.textAlign?.currentValue as (string | null) || this.textAlign;
        const textDecoration = changes.textDecoration?.currentValue as (string | null) || this.textDecoration;
        const wordBreak = changes.wordBreak?.currentValue as (string | null) || this.wordBreak;

        this.hostClasses = [
            `font-weight-${weight}`,
            `font-size-${size}`,
            `color-${color}`,
            `text-transform-${textTransform}`,
            ...(textDecoration ? [`text-decoration-${textDecoration}`] : []),
            ...(textAlign ? [`text-${textAlign}`] : []),
            ...(wordBreak ? [`word-break-${wordBreak}`] : []),
        ].join(' ');
    }
}
